import React from 'react'
import styled from 'styled-components'

const Stars = () => (
  <StyledStars>
    <div id="stars" />
    <div id="stars2" />
    <div id="stars3" />
  </StyledStars>
)

// Compiled CSS from https://codepen.io/saransh/pen/BKJun
const StyledStars = styled.div`
  html {
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
  }

  #stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 347px 191px #fff, 487px 231px #fff, 103px 564px #fff,
      1328px 1008px #fff, 1440px 913px #fff, 347px 1039px #fff, 792px 737px #fff,
      1299px 316px #fff, 721px 1944px #fff, 754px 821px #fff, 798px 446px #fff,
      1937px 658px #fff, 1561px 124px #fff, 1810px 588px #fff, 605px 1635px #fff,
      958px 497px #fff, 1534px 1861px #fff, 176px 764px #fff, 61px 1764px #fff,
      1248px 1935px #fff, 935px 275px #fff, 445px 1978px #fff,
      1252px 1671px #fff, 170px 1034px #fff, 188px 841px #fff, 1850px 986px #fff,
      1392px 1410px #fff, 498px 1640px #fff, 582px 1554px #fff,
      1518px 417px #fff, 1950px 1279px #fff, 591px 68px #fff, 167px 1860px #fff,
      653px 609px #fff, 762px 22px #fff, 431px 1587px #fff, 1008px 500px #fff,
      1397px 1855px #fff, 914px 1000px #fff, 1958px 191px #fff,
      1537px 763px #fff, 613px 646px #fff, 126px 1988px #fff, 292px 1488px #fff,
      985px 1360px #fff, 482px 337px #fff, 763px 9px #fff, 1345px 1624px #fff,
      800px 1134px #fff, 129px 1524px #fff, 1195px 1649px #fff,
      1779px 1873px #fff, 102px 1947px #fff, 1003px 920px #fff, 513px 950px #fff,
      1165px 901px #fff, 1585px 235px #fff, 764px 1367px #fff, 337px 148px #fff,
      1913px 1886px #fff, 1319px 1966px #fff, 87px 1436px #fff, 726px 945px #fff,
      322px 1167px #fff, 243px 1418px #fff, 898px 1793px #fff, 103px 1687px #fff,
      1273px 1240px #fff, 1204px 704px #fff, 108px 892px #fff, 88px 1777px #fff,
      487px 1518px #fff, 161px 1182px #fff, 642px 1865px #fff, 360px 858px #fff,
      1148px 1670px #fff, 1936px 1139px #fff, 1792px 1181px #fff,
      931px 132px #fff, 1737px 210px #fff, 655px 1498px #fff, 1556px 1934px #fff,
      1079px 596px #fff, 854px 721px #fff, 379px 1497px #fff, 1688px 481px #fff,
      1265px 552px #fff, 209px 67px #fff, 1544px 1052px #fff, 130px 1352px #fff,
      557px 1146px #fff, 183px 1170px #fff, 443px 1603px #fff, 382px 226px #fff,
      1957px 1774px #fff, 1545px 879px #fff, 966px 1662px #fff, 473px 313px #fff,
      382px 1493px #fff, 1889px 1380px #fff, 1917px 1467px #fff,
      1870px 564px #fff, 142px 1626px #fff, 1559px 898px #fff,
      1651px 1880px #fff, 1363px 776px #fff, 1667px 1803px #fff,
      1477px 388px #fff, 958px 1658px #fff, 363px 793px #fff, 198px 1909px #fff,
      1878px 783px #fff, 1182px 890px #fff, 1817px 1182px #fff,
      1205px 1488px #fff, 1271px 366px #fff, 1541px 1858px #fff,
      793px 1552px #fff, 1598px 1250px #fff, 518px 887px #fff, 1109px 469px #fff,
      1250px 1155px #fff, 1775px 1261px #fff, 1530px 1524px #fff,
      468px 1320px #fff, 1132px 433px #fff, 326px 1987px #fff, 757px 1055px #fff,
      1236px 1583px #fff, 997px 1451px #fff, 1786px 260px #fff,
      1227px 1368px #fff, 1255px 1858px #fff, 1587px 376px #fff,
      1057px 722px #fff, 935px 1199px #fff, 1842px 62px #fff, 290px 273px #fff,
      890px 679px #fff, 865px 1753px #fff, 49px 1121px #fff, 1857px 822px #fff,
      1789px 1563px #fff, 1639px 1903px #fff, 1547px 1571px #fff,
      194px 1678px #fff, 1758px 1866px #fff, 1833px 1972px #fff,
      1223px 1210px #fff, 1763px 469px #fff, 1686px 1892px #fff,
      453px 1671px #fff, 164px 875px #fff, 242px 1203px #fff, 487px 1569px #fff,
      569px 811px #fff, 1152px 295px #fff, 935px 339px #fff, 228px 1905px #fff,
      926px 1254px #fff, 1294px 434px #fff, 1643px 1411px #fff, 270px 610px #fff,
      366px 193px #fff, 1435px 331px #fff, 1580px 851px #fff, 1137px 200px #fff,
      585px 383px #fff, 1604px 681px #fff, 1628px 543px #fff, 602px 1713px #fff,
      1986px 1764px #fff, 1787px 1055px #fff, 234px 1242px #fff,
      1806px 1316px #fff, 1351px 753px #fff, 294px 1675px #fff,
      1396px 1786px #fff, 760px 51px #fff, 1377px 695px #fff, 1635px 633px #fff,
      96px 688px #fff, 891px 883px #fff, 34px 1538px #fff, 1648px 145px #fff,
      918px 701px #fff, 1843px 1300px #fff, 71px 43px #fff, 529px 1819px #fff,
      1510px 1043px #fff, 429px 130px #fff, 1387px 399px #fff, 405px 907px #fff,
      1752px 885px #fff, 35px 1827px #fff, 1623px 1995px #fff, 153px 1190px #fff,
      1598px 314px #fff, 601px 601px #fff, 1744px 1164px #fff, 617px 1994px #fff,
      157px 1217px #fff, 458px 1095px #fff, 41px 1577px #fff, 65px 1251px #fff,
      1050px 1772px #fff, 1096px 1001px #fff, 1286px 732px #fff,
      1440px 158px #fff, 1907px 454px #fff, 1738px 666px #fff,
      1958px 1377px #fff, 727px 1625px #fff, 758px 995px #fff, 714px 1879px #fff,
      482px 372px #fff, 780px 1329px #fff, 1533px 394px #fff, 1785px 1044px #fff,
      852px 1636px #fff, 797px 1875px #fff, 1919px 994px #fff, 1097px 488px #fff,
      15px 54px #fff, 1709px 14px #fff, 23px 121px #fff, 1397px 616px #fff,
      1542px 576px #fff, 111px 1590px #fff, 912px 207px #fff, 625px 801px #fff,
      1719px 1360px #fff, 653px 1544px #fff, 99px 308px #fff, 1470px 609px #fff,
      1207px 1779px #fff, 569px 1111px #fff, 446px 1898px #fff,
      1492px 1927px #fff, 1556px 1355px #fff, 1901px 1292px #fff,
      1175px 1901px #fff, 108px 327px #fff, 1590px 1467px #fff, 15px 1677px #fff,
      1590px 231px #fff, 1520px 1220px #fff, 207px 1239px #fff,
      1542px 765px #fff, 471px 902px #fff, 1258px 762px #fff, 706px 1272px #fff,
      1245px 570px #fff, 1741px 1854px #fff, 718px 74px #fff, 184px 597px #fff,
      562px 520px #fff, 1085px 158px #fff, 107px 617px #fff, 1672px 535px #fff,
      749px 1659px #fff, 1803px 1918px #fff, 1355px 395px #fff,
      1750px 506px #fff, 1951px 796px #fff, 1042px 221px #fff, 568px 142px #fff,
      1749px 1146px #fff, 1297px 1062px #fff, 212px 385px #fff, 170px 314px #fff,
      316px 306px #fff, 1485px 1343px #fff, 102px 261px #fff, 1239px 387px #fff,
      1630px 1422px #fff, 1904px 219px #fff, 273px 655px #fff, 717px 1939px #fff,
      1295px 465px #fff, 333px 1941px #fff, 1266px 1570px #fff,
      715px 1421px #fff, 715px 1939px #fff, 1219px 199px #fff, 408px 612px #fff,
      479px 1651px #fff, 755px 110px #fff, 982px 1733px #fff, 1338px 1981px #fff,
      234px 104px #fff, 732px 725px #fff, 1411px 1000px #fff, 43px 808px #fff,
      1159px 1200px #fff, 215px 795px #fff, 1814px 1244px #fff, 501px 84px #fff,
      1564px 427px #fff, 974px 1676px #fff, 1365px 1630px #fff,
      1940px 197px #fff, 582px 41px #fff, 1130px 1364px #fff, 1717px 1632px #fff,
      623px 1126px #fff, 944px 642px #fff, 1035px 1216px #fff,
      1154px 1031px #fff, 989px 104px #fff, 1824px 1059px #fff,
      1888px 1372px #fff, 964px 480px #fff, 926px 1223px #fff, 711px 807px #fff,
      1376px 1268px #fff, 1198px 1818px #fff, 1113px 901px #fff,
      1047px 1074px #fff, 796px 296px #fff, 271px 1069px #fff, 518px 486px #fff,
      487px 234px #fff, 562px 298px #fff, 184px 604px #fff, 136px 1742px #fff,
      1051px 1224px #fff, 535px 1635px #fff, 303px 412px #fff, 1838px 187px #fff,
      846px 1615px #fff, 1214px 1853px #fff, 1288px 1626px #fff,
      741px 963px #fff, 883px 1109px #fff, 594px 997px #fff, 947px 158px #fff,
      93px 465px #fff, 486px 222px #fff, 122px 28px #fff, 1494px 911px #fff,
      818px 841px #fff, 1097px 1044px #fff, 1552px 2000px #fff,
      668px 1753px #fff, 1636px 895px #fff, 1399px 1779px #fff, 14px 936px #fff,
      1326px 1513px #fff, 499px 570px #fff, 328px 433px #fff, 544px 1008px #fff,
      1874px 951px #fff, 705px 592px #fff, 537px 1272px #fff, 1034px 387px #fff,
      373px 1824px #fff, 1672px 1391px #fff, 391px 723px #fff, 1946px 909px #fff,
      1626px 641px #fff, 965px 284px #fff, 823px 1944px #fff, 319px 1277px #fff,
      1815px 1504px #fff, 131px 1371px #fff, 1557px 309px #fff, 64px 812px #fff,
      671px 567px #fff, 1725px 1688px #fff, 94px 506px #fff, 1392px 440px #fff,
      660px 744px #fff, 1610px 1713px #fff, 1920px 1913px #fff, 674px 784px #fff,
      762px 369px #fff, 1711px 194px #fff, 1876px 1118px #fff, 554px 291px #fff,
      1128px 323px #fff, 970px 382px #fff, 1924px 473px #fff, 1130px 1536px #fff,
      1810px 1129px #fff, 486px 1884px #fff, 1575px 1682px #fff,
      1691px 1271px #fff, 1573px 385px #fff, 671px 1275px #fff, 27px 559px #fff,
      61px 1624px #fff, 884px 495px #fff, 1244px 1827px #fff, 923px 1300px #fff,
      1784px 1492px #fff, 1455px 883px #fff, 773px 1903px #fff, 1661px 54px #fff,
      1347px 1283px #fff, 844px 1813px #fff, 536px 277px #fff, 1047px 762px #fff,
      274px 449px #fff, 139px 813px #fff, 387px 1426px #fff, 815px 1026px #fff,
      1384px 1935px #fff, 980px 404px #fff, 814px 1421px #fff,
      1527px 1170px #fff, 1300px 281px #fff, 972px 642px #fff,
      1336px 1414px #fff, 28px 131px #fff, 636px 324px #fff, 619px 533px #fff,
      56px 1090px #fff, 1290px 429px #fff, 1716px 1426px #fff, 1565px 523px #fff,
      587px 2000px #fff, 493px 653px #fff, 682px 868px #fff, 1602px 628px #fff,
      1142px 1466px #fff, 1136px 1888px #fff, 2000px 1491px #fff,
      1199px 260px #fff, 1059px 686px #fff, 1690px 952px #fff, 1205px 862px #fff,
      1267px 1284px #fff, 1221px 1164px #fff, 2px 456px #fff, 214px 421px #fff,
      1049px 1068px #fff, 357px 1782px #fff, 648px 569px #fff,
      1960px 1394px #fff, 769px 6px #fff, 1198px 76px #fff, 1994px 349px #fff,
      770px 1104px #fff, 206px 1586px #fff, 827px 520px #fff, 75px 1298px #fff,
      797px 1716px #fff, 273px 1980px #fff, 605px 1640px #fff,
      1251px 2000px #fff, 975px 812px #fff, 553px 976px #fff, 1791px 1901px #fff,
      588px 691px #fff, 1183px 1009px #fff, 1005px 1219px #fff, 9px 347px #fff,
      940px 559px #fff, 1176px 1750px #fff, 1353px 1157px #fff,
      1624px 449px #fff, 747px 518px #fff, 1494px 121px #fff, 1032px 1582px #fff,
      238px 148px #fff, 917px 647px #fff, 1403px 809px #fff, 1303px 652px #fff,
      873px 16px #fff, 231px 472px #fff, 1886px 317px #fff, 979px 214px #fff,
      1694px 1364px #fff, 1740px 1198px #fff, 1147px 331px #fff,
      261px 630px #fff, 1991px 612px #fff, 1880px 1591px #fff, 501px 1262px #fff,
      1465px 413px #fff, 1578px 1814px #fff, 1598px 880px #fff,
      1029px 896px #fff, 355px 730px #fff, 70px 832px #fff, 1609px 1349px #fff,
      56px 1766px #fff, 593px 59px #fff, 1326px 279px #fff, 788px 1781px #fff,
      1171px 460px #fff, 1576px 524px #fff, 1667px 365px #fff, 641px 303px #fff,
      142px 1286px #fff, 1228px 1555px #fff, 356px 240px #fff, 450px 1940px #fff,
      666px 1951px #fff, 160px 33px #fff, 1599px 376px #fff, 1296px 1512px #fff,
      453px 412px #fff, 323px 1151px #fff, 1989px 1906px #fff,
      1828px 1586px #fff, 1674px 1646px #fff, 1569px 624px #fff,
      1418px 1088px #fff, 821px 1447px #fff, 977px 1337px #fff,
      158px 1993px #fff, 1012px 360px #fff, 76px 1665px #fff, 501px 389px #fff,
      1785px 1146px #fff, 672px 499px #fff, 1311px 1602px #fff,
      675px 1925px #fff, 822px 1606px #fff, 137px 1452px #fff,
      1386px 1942px #fff, 1384px 1921px #fff, 1031px 798px #fff,
      1265px 1850px #fff, 788px 1005px #fff, 1621px 1294px #fff,
      1271px 585px #fff, 737px 1478px #fff, 743px 555px #fff, 1380px 629px #fff,
      95px 240px #fff, 291px 832px #fff, 604px 186px #fff, 386px 99px #fff,
      1474px 709px #fff, 1270px 1295px #fff, 335px 77px #fff, 1690px 1926px #fff,
      1649px 1258px #fff, 583px 977px #fff, 718px 60px #fff, 1196px 936px #fff,
      1909px 767px #fff, 1844px 1968px #fff, 1359px 133px #fff,
      1993px 649px #fff, 1874px 1669px #fff, 1625px 266px #fff, 35px 606px #fff,
      118px 1138px #fff, 648px 1225px #fff, 772px 1475px #fff, 586px 1012px #fff,
      1922px 465px #fff, 188px 222px #fff, 1715px 1232px #fff, 416px 73px #fff,
      733px 1139px #fff, 1092px 284px #fff, 1718px 274px #fff, 659px 599px #fff,
      509px 843px #fff, 1203px 613px #fff, 410px 3px #fff, 812px 10px #fff,
      501px 765px #fff, 922px 596px #fff, 1396px 1061px #fff, 418px 1038px #fff,
      689px 1791px #fff, 1950px 1486px #fff, 1854px 1203px #fff,
      1514px 795px #fff, 713px 1109px #fff, 1087px 210px #fff, 559px 1345px #fff,
      666px 1005px #fff, 1538px 306px #fff, 805px 395px #fff, 1634px 77px #fff,
      1774px 1131px #fff, 1498px 1143px #fff, 1864px 1207px #fff,
      1847px 1624px #fff, 436px 1709px #fff, 188px 1810px #fff,
      113px 1141px #fff, 250px 801px #fff, 755px 1863px #fff, 1545px 726px #fff,
      479px 993px #fff, 444px 1847px #fff, 1354px 1897px #fff,
      1207px 1076px #fff, 1321px 518px #fff, 1429px 984px #fff, 1856px 66px #fff,
      1410px 217px #fff, 1893px 1595px #fff, 1104px 890px #fff,
      1905px 1103px #fff, 1568px 36px #fff, 644px 1118px #fff, 138px 1588px #fff,
      264px 1861px #fff, 516px 1546px #fff, 405px 394px #fff, 810px 160px #fff,
      1446px 1485px #fff, 1475px 380px #fff, 404px 1493px #fff, 424px 800px #fff,
      864px 561px #fff, 1539px 675px #fff, 1374px 249px #fff, 1042px 1908px #fff,
      977px 967px #fff, 1976px 1421px #fff, 1397px 1020px #fff, 397px 65px #fff,
      1420px 87px #fff, 819px 1950px #fff, 699px 1625px #fff, 1648px 26px #fff,
      1775px 1595px #fff, 891px 1550px #fff, 319px 1720px #fff,
      440px 1075px #fff, 1747px 1190px #fff, 1878px 1767px #fff,
      423px 532px #fff, 1227px 1692px #fff, 1200px 1699px #fff,
      1263px 1496px #fff, 328px 102px #fff, 778px 1862px #fff, 165px 1018px #fff,
      814px 911px #fff, 458px 377px #fff, 1569px 539px #fff, 872px 1500px #fff,
      1855px 823px #fff, 1314px 1257px #fff, 1031px 1951px #fff, 650px 35px #fff,
      844px 273px #fff, 1503px 692px #fff, 1147px 1358px #fff, 92px 1578px #fff,
      1651px 1526px #fff, 1954px 913px #fff, 1570px 767px #fff, 668px 259px #fff,
      1426px 676px #fff, 906px 163px #fff, 29px 542px #fff, 1279px 1322px #fff,
      1383px 771px #fff, 25px 434px #fff, 1251px 396px #fff, 1375px 1313px #fff,
      609px 200px #fff, 1323px 573px #fff, 816px 1471px #fff, 407px 1284px #fff,
      1578px 388px #fff, 593px 432px #fff, 1401px 1153px #fff, 477px 665px #fff,
      241px 1080px #fff, 1477px 967px #fff, 508px 1138px #fff, 1352px 86px #fff,
      1439px 1898px #fff, 81px 1395px #fff, 1074px 510px #fff,
      1295px 1231px #fff, 57px 1583px #fff, 1697px 1848px #fff, 317px 638px #fff,
      1661px 707px #fff, 181px 1070px #fff, 1938px 1142px #fff,
      1316px 1169px #fff, 546px 498px #fff, 351px 1019px #fff, 563px 1163px #fff,
      826px 111px #fff, 469px 223px #fff, 1685px 1007px #fff, 1636px 717px #fff,
      1242px 1955px #fff, 523px 806px #fff, 438px 559px #fff, 1511px 1900px #fff,
      180px 772px #fff, 1717px 822px #fff, 920px 123px #fff;
    animation: animStar 50s linear infinite;
  }
  #stars:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 347px 191px #fff, 487px 231px #fff, 103px 564px #fff,
      1328px 1008px #fff, 1440px 913px #fff, 347px 1039px #fff, 792px 737px #fff,
      1299px 316px #fff, 721px 1944px #fff, 754px 821px #fff, 798px 446px #fff,
      1937px 658px #fff, 1561px 124px #fff, 1810px 588px #fff, 605px 1635px #fff,
      958px 497px #fff, 1534px 1861px #fff, 176px 764px #fff, 61px 1764px #fff,
      1248px 1935px #fff, 935px 275px #fff, 445px 1978px #fff,
      1252px 1671px #fff, 170px 1034px #fff, 188px 841px #fff, 1850px 986px #fff,
      1392px 1410px #fff, 498px 1640px #fff, 582px 1554px #fff,
      1518px 417px #fff, 1950px 1279px #fff, 591px 68px #fff, 167px 1860px #fff,
      653px 609px #fff, 762px 22px #fff, 431px 1587px #fff, 1008px 500px #fff,
      1397px 1855px #fff, 914px 1000px #fff, 1958px 191px #fff,
      1537px 763px #fff, 613px 646px #fff, 126px 1988px #fff, 292px 1488px #fff,
      985px 1360px #fff, 482px 337px #fff, 763px 9px #fff, 1345px 1624px #fff,
      800px 1134px #fff, 129px 1524px #fff, 1195px 1649px #fff,
      1779px 1873px #fff, 102px 1947px #fff, 1003px 920px #fff, 513px 950px #fff,
      1165px 901px #fff, 1585px 235px #fff, 764px 1367px #fff, 337px 148px #fff,
      1913px 1886px #fff, 1319px 1966px #fff, 87px 1436px #fff, 726px 945px #fff,
      322px 1167px #fff, 243px 1418px #fff, 898px 1793px #fff, 103px 1687px #fff,
      1273px 1240px #fff, 1204px 704px #fff, 108px 892px #fff, 88px 1777px #fff,
      487px 1518px #fff, 161px 1182px #fff, 642px 1865px #fff, 360px 858px #fff,
      1148px 1670px #fff, 1936px 1139px #fff, 1792px 1181px #fff,
      931px 132px #fff, 1737px 210px #fff, 655px 1498px #fff, 1556px 1934px #fff,
      1079px 596px #fff, 854px 721px #fff, 379px 1497px #fff, 1688px 481px #fff,
      1265px 552px #fff, 209px 67px #fff, 1544px 1052px #fff, 130px 1352px #fff,
      557px 1146px #fff, 183px 1170px #fff, 443px 1603px #fff, 382px 226px #fff,
      1957px 1774px #fff, 1545px 879px #fff, 966px 1662px #fff, 473px 313px #fff,
      382px 1493px #fff, 1889px 1380px #fff, 1917px 1467px #fff,
      1870px 564px #fff, 142px 1626px #fff, 1559px 898px #fff,
      1651px 1880px #fff, 1363px 776px #fff, 1667px 1803px #fff,
      1477px 388px #fff, 958px 1658px #fff, 363px 793px #fff, 198px 1909px #fff,
      1878px 783px #fff, 1182px 890px #fff, 1817px 1182px #fff,
      1205px 1488px #fff, 1271px 366px #fff, 1541px 1858px #fff,
      793px 1552px #fff, 1598px 1250px #fff, 518px 887px #fff, 1109px 469px #fff,
      1250px 1155px #fff, 1775px 1261px #fff, 1530px 1524px #fff,
      468px 1320px #fff, 1132px 433px #fff, 326px 1987px #fff, 757px 1055px #fff,
      1236px 1583px #fff, 997px 1451px #fff, 1786px 260px #fff,
      1227px 1368px #fff, 1255px 1858px #fff, 1587px 376px #fff,
      1057px 722px #fff, 935px 1199px #fff, 1842px 62px #fff, 290px 273px #fff,
      890px 679px #fff, 865px 1753px #fff, 49px 1121px #fff, 1857px 822px #fff,
      1789px 1563px #fff, 1639px 1903px #fff, 1547px 1571px #fff,
      194px 1678px #fff, 1758px 1866px #fff, 1833px 1972px #fff,
      1223px 1210px #fff, 1763px 469px #fff, 1686px 1892px #fff,
      453px 1671px #fff, 164px 875px #fff, 242px 1203px #fff, 487px 1569px #fff,
      569px 811px #fff, 1152px 295px #fff, 935px 339px #fff, 228px 1905px #fff,
      926px 1254px #fff, 1294px 434px #fff, 1643px 1411px #fff, 270px 610px #fff,
      366px 193px #fff, 1435px 331px #fff, 1580px 851px #fff, 1137px 200px #fff,
      585px 383px #fff, 1604px 681px #fff, 1628px 543px #fff, 602px 1713px #fff,
      1986px 1764px #fff, 1787px 1055px #fff, 234px 1242px #fff,
      1806px 1316px #fff, 1351px 753px #fff, 294px 1675px #fff,
      1396px 1786px #fff, 760px 51px #fff, 1377px 695px #fff, 1635px 633px #fff,
      96px 688px #fff, 891px 883px #fff, 34px 1538px #fff, 1648px 145px #fff,
      918px 701px #fff, 1843px 1300px #fff, 71px 43px #fff, 529px 1819px #fff,
      1510px 1043px #fff, 429px 130px #fff, 1387px 399px #fff, 405px 907px #fff,
      1752px 885px #fff, 35px 1827px #fff, 1623px 1995px #fff, 153px 1190px #fff,
      1598px 314px #fff, 601px 601px #fff, 1744px 1164px #fff, 617px 1994px #fff,
      157px 1217px #fff, 458px 1095px #fff, 41px 1577px #fff, 65px 1251px #fff,
      1050px 1772px #fff, 1096px 1001px #fff, 1286px 732px #fff,
      1440px 158px #fff, 1907px 454px #fff, 1738px 666px #fff,
      1958px 1377px #fff, 727px 1625px #fff, 758px 995px #fff, 714px 1879px #fff,
      482px 372px #fff, 780px 1329px #fff, 1533px 394px #fff, 1785px 1044px #fff,
      852px 1636px #fff, 797px 1875px #fff, 1919px 994px #fff, 1097px 488px #fff,
      15px 54px #fff, 1709px 14px #fff, 23px 121px #fff, 1397px 616px #fff,
      1542px 576px #fff, 111px 1590px #fff, 912px 207px #fff, 625px 801px #fff,
      1719px 1360px #fff, 653px 1544px #fff, 99px 308px #fff, 1470px 609px #fff,
      1207px 1779px #fff, 569px 1111px #fff, 446px 1898px #fff,
      1492px 1927px #fff, 1556px 1355px #fff, 1901px 1292px #fff,
      1175px 1901px #fff, 108px 327px #fff, 1590px 1467px #fff, 15px 1677px #fff,
      1590px 231px #fff, 1520px 1220px #fff, 207px 1239px #fff,
      1542px 765px #fff, 471px 902px #fff, 1258px 762px #fff, 706px 1272px #fff,
      1245px 570px #fff, 1741px 1854px #fff, 718px 74px #fff, 184px 597px #fff,
      562px 520px #fff, 1085px 158px #fff, 107px 617px #fff, 1672px 535px #fff,
      749px 1659px #fff, 1803px 1918px #fff, 1355px 395px #fff,
      1750px 506px #fff, 1951px 796px #fff, 1042px 221px #fff, 568px 142px #fff,
      1749px 1146px #fff, 1297px 1062px #fff, 212px 385px #fff, 170px 314px #fff,
      316px 306px #fff, 1485px 1343px #fff, 102px 261px #fff, 1239px 387px #fff,
      1630px 1422px #fff, 1904px 219px #fff, 273px 655px #fff, 717px 1939px #fff,
      1295px 465px #fff, 333px 1941px #fff, 1266px 1570px #fff,
      715px 1421px #fff, 715px 1939px #fff, 1219px 199px #fff, 408px 612px #fff,
      479px 1651px #fff, 755px 110px #fff, 982px 1733px #fff, 1338px 1981px #fff,
      234px 104px #fff, 732px 725px #fff, 1411px 1000px #fff, 43px 808px #fff,
      1159px 1200px #fff, 215px 795px #fff, 1814px 1244px #fff, 501px 84px #fff,
      1564px 427px #fff, 974px 1676px #fff, 1365px 1630px #fff,
      1940px 197px #fff, 582px 41px #fff, 1130px 1364px #fff, 1717px 1632px #fff,
      623px 1126px #fff, 944px 642px #fff, 1035px 1216px #fff,
      1154px 1031px #fff, 989px 104px #fff, 1824px 1059px #fff,
      1888px 1372px #fff, 964px 480px #fff, 926px 1223px #fff, 711px 807px #fff,
      1376px 1268px #fff, 1198px 1818px #fff, 1113px 901px #fff,
      1047px 1074px #fff, 796px 296px #fff, 271px 1069px #fff, 518px 486px #fff,
      487px 234px #fff, 562px 298px #fff, 184px 604px #fff, 136px 1742px #fff,
      1051px 1224px #fff, 535px 1635px #fff, 303px 412px #fff, 1838px 187px #fff,
      846px 1615px #fff, 1214px 1853px #fff, 1288px 1626px #fff,
      741px 963px #fff, 883px 1109px #fff, 594px 997px #fff, 947px 158px #fff,
      93px 465px #fff, 486px 222px #fff, 122px 28px #fff, 1494px 911px #fff,
      818px 841px #fff, 1097px 1044px #fff, 1552px 2000px #fff,
      668px 1753px #fff, 1636px 895px #fff, 1399px 1779px #fff, 14px 936px #fff,
      1326px 1513px #fff, 499px 570px #fff, 328px 433px #fff, 544px 1008px #fff,
      1874px 951px #fff, 705px 592px #fff, 537px 1272px #fff, 1034px 387px #fff,
      373px 1824px #fff, 1672px 1391px #fff, 391px 723px #fff, 1946px 909px #fff,
      1626px 641px #fff, 965px 284px #fff, 823px 1944px #fff, 319px 1277px #fff,
      1815px 1504px #fff, 131px 1371px #fff, 1557px 309px #fff, 64px 812px #fff,
      671px 567px #fff, 1725px 1688px #fff, 94px 506px #fff, 1392px 440px #fff,
      660px 744px #fff, 1610px 1713px #fff, 1920px 1913px #fff, 674px 784px #fff,
      762px 369px #fff, 1711px 194px #fff, 1876px 1118px #fff, 554px 291px #fff,
      1128px 323px #fff, 970px 382px #fff, 1924px 473px #fff, 1130px 1536px #fff,
      1810px 1129px #fff, 486px 1884px #fff, 1575px 1682px #fff,
      1691px 1271px #fff, 1573px 385px #fff, 671px 1275px #fff, 27px 559px #fff,
      61px 1624px #fff, 884px 495px #fff, 1244px 1827px #fff, 923px 1300px #fff,
      1784px 1492px #fff, 1455px 883px #fff, 773px 1903px #fff, 1661px 54px #fff,
      1347px 1283px #fff, 844px 1813px #fff, 536px 277px #fff, 1047px 762px #fff,
      274px 449px #fff, 139px 813px #fff, 387px 1426px #fff, 815px 1026px #fff,
      1384px 1935px #fff, 980px 404px #fff, 814px 1421px #fff,
      1527px 1170px #fff, 1300px 281px #fff, 972px 642px #fff,
      1336px 1414px #fff, 28px 131px #fff, 636px 324px #fff, 619px 533px #fff,
      56px 1090px #fff, 1290px 429px #fff, 1716px 1426px #fff, 1565px 523px #fff,
      587px 2000px #fff, 493px 653px #fff, 682px 868px #fff, 1602px 628px #fff,
      1142px 1466px #fff, 1136px 1888px #fff, 2000px 1491px #fff,
      1199px 260px #fff, 1059px 686px #fff, 1690px 952px #fff, 1205px 862px #fff,
      1267px 1284px #fff, 1221px 1164px #fff, 2px 456px #fff, 214px 421px #fff,
      1049px 1068px #fff, 357px 1782px #fff, 648px 569px #fff,
      1960px 1394px #fff, 769px 6px #fff, 1198px 76px #fff, 1994px 349px #fff,
      770px 1104px #fff, 206px 1586px #fff, 827px 520px #fff, 75px 1298px #fff,
      797px 1716px #fff, 273px 1980px #fff, 605px 1640px #fff,
      1251px 2000px #fff, 975px 812px #fff, 553px 976px #fff, 1791px 1901px #fff,
      588px 691px #fff, 1183px 1009px #fff, 1005px 1219px #fff, 9px 347px #fff,
      940px 559px #fff, 1176px 1750px #fff, 1353px 1157px #fff,
      1624px 449px #fff, 747px 518px #fff, 1494px 121px #fff, 1032px 1582px #fff,
      238px 148px #fff, 917px 647px #fff, 1403px 809px #fff, 1303px 652px #fff,
      873px 16px #fff, 231px 472px #fff, 1886px 317px #fff, 979px 214px #fff,
      1694px 1364px #fff, 1740px 1198px #fff, 1147px 331px #fff,
      261px 630px #fff, 1991px 612px #fff, 1880px 1591px #fff, 501px 1262px #fff,
      1465px 413px #fff, 1578px 1814px #fff, 1598px 880px #fff,
      1029px 896px #fff, 355px 730px #fff, 70px 832px #fff, 1609px 1349px #fff,
      56px 1766px #fff, 593px 59px #fff, 1326px 279px #fff, 788px 1781px #fff,
      1171px 460px #fff, 1576px 524px #fff, 1667px 365px #fff, 641px 303px #fff,
      142px 1286px #fff, 1228px 1555px #fff, 356px 240px #fff, 450px 1940px #fff,
      666px 1951px #fff, 160px 33px #fff, 1599px 376px #fff, 1296px 1512px #fff,
      453px 412px #fff, 323px 1151px #fff, 1989px 1906px #fff,
      1828px 1586px #fff, 1674px 1646px #fff, 1569px 624px #fff,
      1418px 1088px #fff, 821px 1447px #fff, 977px 1337px #fff,
      158px 1993px #fff, 1012px 360px #fff, 76px 1665px #fff, 501px 389px #fff,
      1785px 1146px #fff, 672px 499px #fff, 1311px 1602px #fff,
      675px 1925px #fff, 822px 1606px #fff, 137px 1452px #fff,
      1386px 1942px #fff, 1384px 1921px #fff, 1031px 798px #fff,
      1265px 1850px #fff, 788px 1005px #fff, 1621px 1294px #fff,
      1271px 585px #fff, 737px 1478px #fff, 743px 555px #fff, 1380px 629px #fff,
      95px 240px #fff, 291px 832px #fff, 604px 186px #fff, 386px 99px #fff,
      1474px 709px #fff, 1270px 1295px #fff, 335px 77px #fff, 1690px 1926px #fff,
      1649px 1258px #fff, 583px 977px #fff, 718px 60px #fff, 1196px 936px #fff,
      1909px 767px #fff, 1844px 1968px #fff, 1359px 133px #fff,
      1993px 649px #fff, 1874px 1669px #fff, 1625px 266px #fff, 35px 606px #fff,
      118px 1138px #fff, 648px 1225px #fff, 772px 1475px #fff, 586px 1012px #fff,
      1922px 465px #fff, 188px 222px #fff, 1715px 1232px #fff, 416px 73px #fff,
      733px 1139px #fff, 1092px 284px #fff, 1718px 274px #fff, 659px 599px #fff,
      509px 843px #fff, 1203px 613px #fff, 410px 3px #fff, 812px 10px #fff,
      501px 765px #fff, 922px 596px #fff, 1396px 1061px #fff, 418px 1038px #fff,
      689px 1791px #fff, 1950px 1486px #fff, 1854px 1203px #fff,
      1514px 795px #fff, 713px 1109px #fff, 1087px 210px #fff, 559px 1345px #fff,
      666px 1005px #fff, 1538px 306px #fff, 805px 395px #fff, 1634px 77px #fff,
      1774px 1131px #fff, 1498px 1143px #fff, 1864px 1207px #fff,
      1847px 1624px #fff, 436px 1709px #fff, 188px 1810px #fff,
      113px 1141px #fff, 250px 801px #fff, 755px 1863px #fff, 1545px 726px #fff,
      479px 993px #fff, 444px 1847px #fff, 1354px 1897px #fff,
      1207px 1076px #fff, 1321px 518px #fff, 1429px 984px #fff, 1856px 66px #fff,
      1410px 217px #fff, 1893px 1595px #fff, 1104px 890px #fff,
      1905px 1103px #fff, 1568px 36px #fff, 644px 1118px #fff, 138px 1588px #fff,
      264px 1861px #fff, 516px 1546px #fff, 405px 394px #fff, 810px 160px #fff,
      1446px 1485px #fff, 1475px 380px #fff, 404px 1493px #fff, 424px 800px #fff,
      864px 561px #fff, 1539px 675px #fff, 1374px 249px #fff, 1042px 1908px #fff,
      977px 967px #fff, 1976px 1421px #fff, 1397px 1020px #fff, 397px 65px #fff,
      1420px 87px #fff, 819px 1950px #fff, 699px 1625px #fff, 1648px 26px #fff,
      1775px 1595px #fff, 891px 1550px #fff, 319px 1720px #fff,
      440px 1075px #fff, 1747px 1190px #fff, 1878px 1767px #fff,
      423px 532px #fff, 1227px 1692px #fff, 1200px 1699px #fff,
      1263px 1496px #fff, 328px 102px #fff, 778px 1862px #fff, 165px 1018px #fff,
      814px 911px #fff, 458px 377px #fff, 1569px 539px #fff, 872px 1500px #fff,
      1855px 823px #fff, 1314px 1257px #fff, 1031px 1951px #fff, 650px 35px #fff,
      844px 273px #fff, 1503px 692px #fff, 1147px 1358px #fff, 92px 1578px #fff,
      1651px 1526px #fff, 1954px 913px #fff, 1570px 767px #fff, 668px 259px #fff,
      1426px 676px #fff, 906px 163px #fff, 29px 542px #fff, 1279px 1322px #fff,
      1383px 771px #fff, 25px 434px #fff, 1251px 396px #fff, 1375px 1313px #fff,
      609px 200px #fff, 1323px 573px #fff, 816px 1471px #fff, 407px 1284px #fff,
      1578px 388px #fff, 593px 432px #fff, 1401px 1153px #fff, 477px 665px #fff,
      241px 1080px #fff, 1477px 967px #fff, 508px 1138px #fff, 1352px 86px #fff,
      1439px 1898px #fff, 81px 1395px #fff, 1074px 510px #fff,
      1295px 1231px #fff, 57px 1583px #fff, 1697px 1848px #fff, 317px 638px #fff,
      1661px 707px #fff, 181px 1070px #fff, 1938px 1142px #fff,
      1316px 1169px #fff, 546px 498px #fff, 351px 1019px #fff, 563px 1163px #fff,
      826px 111px #fff, 469px 223px #fff, 1685px 1007px #fff, 1636px 717px #fff,
      1242px 1955px #fff, 523px 806px #fff, 438px 559px #fff, 1511px 1900px #fff,
      180px 772px #fff, 1717px 822px #fff, 920px 123px #fff;
  }

  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 565px 634px #fff, 1955px 807px #fff, 848px 1725px #fff,
      73px 1546px #fff, 1937px 355px #fff, 1992px 817px #fff, 425px 484px #fff,
      61px 1373px #fff, 1172px 1416px #fff, 1113px 1215px #fff,
      1769px 580px #fff, 1389px 1500px #fff, 847px 972px #fff, 353px 177px #fff,
      1195px 1580px #fff, 1169px 1046px #fff, 291px 559px #fff, 955px 531px #fff,
      669px 1398px #fff, 320px 1144px #fff, 1272px 228px #fff, 60px 698px #fff,
      1918px 1753px #fff, 395px 170px #fff, 229px 67px #fff, 743px 1138px #fff,
      1909px 1045px #fff, 601px 1441px #fff, 1410px 576px #fff,
      758px 1021px #fff, 1132px 1607px #fff, 1041px 606px #fff,
      1658px 396px #fff, 704px 481px #fff, 81px 1221px #fff, 1270px 48px #fff,
      1869px 236px #fff, 1200px 1841px #fff, 1347px 1143px #fff,
      472px 991px #fff, 1031px 1050px #fff, 587px 1800px #fff, 767px 281px #fff,
      1192px 285px #fff, 1098px 1002px #fff, 1534px 294px #fff,
      1625px 1872px #fff, 1083px 379px #fff, 1728px 1738px #fff,
      850px 1118px #fff, 549px 526px #fff, 797px 861px #fff, 1920px 1485px #fff,
      601px 1116px #fff, 1824px 1275px #fff, 456px 1537px #fff,
      1143px 1836px #fff, 346px 1186px #fff, 1560px 1121px #fff,
      1434px 306px #fff, 1554px 1848px #fff, 1954px 1259px #fff,
      60px 1134px #fff, 217px 934px #fff, 1425px 1738px #fff, 1366px 76px #fff,
      644px 1602px #fff, 300px 604px #fff, 1537px 355px #fff, 428px 476px #fff,
      551px 1870px #fff, 266px 464px #fff, 590px 435px #fff, 1316px 1126px #fff,
      1765px 954px #fff, 305px 1912px #fff, 1124px 1525px #fff,
      1808px 273px #fff, 1014px 1618px #fff, 1296px 35px #fff,
      1406px 1289px #fff, 1312px 1392px #fff, 1972px 498px #fff,
      154px 784px #fff, 345px 71px #fff, 1202px 146px #fff, 1125px 1031px #fff,
      147px 1098px #fff, 1429px 572px #fff, 544px 140px #fff, 1827px 462px #fff,
      1772px 899px #fff, 817px 1123px #fff, 1029px 1961px #fff,
      1051px 778px #fff, 221px 1142px #fff, 1424px 403px #fff, 268px 1111px #fff,
      1281px 1039px #fff, 467px 1305px #fff, 404px 232px #fff, 574px 606px #fff,
      1424px 562px #fff, 228px 1740px #fff, 493px 1039px #fff, 375px 1820px #fff,
      788px 1184px #fff, 427px 230px #fff, 843px 924px #fff, 762px 1358px #fff,
      1188px 856px #fff, 1854px 1813px #fff, 643px 1514px #fff, 846px 965px #fff,
      1432px 1086px #fff, 550px 827px #fff, 667px 1782px #fff,
      1910px 1895px #fff, 692px 1935px #fff, 1713px 921px #fff,
      577px 1387px #fff, 1500px 1371px #fff, 1384px 492px #fff,
      1052px 1693px #fff, 916px 794px #fff, 1979px 1343px #fff, 943px 839px #fff,
      1851px 136px #fff, 21px 1554px #fff, 1789px 266px #fff, 1221px 17px #fff,
      369px 151px #fff, 1797px 1680px #fff, 1225px 1354px #fff, 915px 498px #fff,
      3px 1334px #fff, 752px 1743px #fff, 978px 1718px #fff, 753px 136px #fff,
      1015px 1098px #fff, 1561px 1154px #fff, 1762px 195px #fff,
      1715px 947px #fff, 1486px 501px #fff, 1089px 1609px #fff,
      1077px 577px #fff, 102px 780px #fff, 36px 826px #fff, 1288px 614px #fff,
      1601px 1687px #fff, 164px 655px #fff, 9px 89px #fff, 1010px 83px #fff,
      831px 58px #fff, 1221px 1404px #fff, 225px 290px #fff, 78px 919px #fff,
      321px 1684px #fff, 1372px 1405px #fff, 1854px 331px #fff,
      251px 1028px #fff, 1193px 652px #fff, 169px 1104px #fff,
      1790px 1505px #fff, 1126px 72px #fff, 1870px 1659px #fff, 1476px 58px #fff,
      1335px 1789px #fff, 443px 1809px #fff, 1457px 821px #fff,
      1594px 1096px #fff, 1371px 1538px #fff, 959px 359px #fff, 370px 649px #fff,
      1338px 1084px #fff, 1839px 710px #fff, 778px 1364px #fff,
      1115px 427px #fff, 1756px 1411px #fff, 627px 1920px #fff,
      999px 1354px #fff, 1974px 1946px #fff, 845px 1498px #fff,
      1642px 1888px #fff, 1799px 35px #fff, 1074px 1744px #fff,
      265px 1060px #fff, 772px 1538px #fff, 537px 881px #fff, 109px 141px #fff,
      903px 1364px #fff, 1549px 815px #fff, 227px 860px #fff, 1960px 149px #fff,
      1936px 1532px #fff, 212px 1291px #fff, 579px 608px #fff,
      1707px 1152px #fff, 750px 1066px #fff, 702px 668px #fff;
    animation: animStar 100s linear infinite;
  }
  #stars2:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 565px 634px #fff, 1955px 807px #fff, 848px 1725px #fff,
      73px 1546px #fff, 1937px 355px #fff, 1992px 817px #fff, 425px 484px #fff,
      61px 1373px #fff, 1172px 1416px #fff, 1113px 1215px #fff,
      1769px 580px #fff, 1389px 1500px #fff, 847px 972px #fff, 353px 177px #fff,
      1195px 1580px #fff, 1169px 1046px #fff, 291px 559px #fff, 955px 531px #fff,
      669px 1398px #fff, 320px 1144px #fff, 1272px 228px #fff, 60px 698px #fff,
      1918px 1753px #fff, 395px 170px #fff, 229px 67px #fff, 743px 1138px #fff,
      1909px 1045px #fff, 601px 1441px #fff, 1410px 576px #fff,
      758px 1021px #fff, 1132px 1607px #fff, 1041px 606px #fff,
      1658px 396px #fff, 704px 481px #fff, 81px 1221px #fff, 1270px 48px #fff,
      1869px 236px #fff, 1200px 1841px #fff, 1347px 1143px #fff,
      472px 991px #fff, 1031px 1050px #fff, 587px 1800px #fff, 767px 281px #fff,
      1192px 285px #fff, 1098px 1002px #fff, 1534px 294px #fff,
      1625px 1872px #fff, 1083px 379px #fff, 1728px 1738px #fff,
      850px 1118px #fff, 549px 526px #fff, 797px 861px #fff, 1920px 1485px #fff,
      601px 1116px #fff, 1824px 1275px #fff, 456px 1537px #fff,
      1143px 1836px #fff, 346px 1186px #fff, 1560px 1121px #fff,
      1434px 306px #fff, 1554px 1848px #fff, 1954px 1259px #fff,
      60px 1134px #fff, 217px 934px #fff, 1425px 1738px #fff, 1366px 76px #fff,
      644px 1602px #fff, 300px 604px #fff, 1537px 355px #fff, 428px 476px #fff,
      551px 1870px #fff, 266px 464px #fff, 590px 435px #fff, 1316px 1126px #fff,
      1765px 954px #fff, 305px 1912px #fff, 1124px 1525px #fff,
      1808px 273px #fff, 1014px 1618px #fff, 1296px 35px #fff,
      1406px 1289px #fff, 1312px 1392px #fff, 1972px 498px #fff,
      154px 784px #fff, 345px 71px #fff, 1202px 146px #fff, 1125px 1031px #fff,
      147px 1098px #fff, 1429px 572px #fff, 544px 140px #fff, 1827px 462px #fff,
      1772px 899px #fff, 817px 1123px #fff, 1029px 1961px #fff,
      1051px 778px #fff, 221px 1142px #fff, 1424px 403px #fff, 268px 1111px #fff,
      1281px 1039px #fff, 467px 1305px #fff, 404px 232px #fff, 574px 606px #fff,
      1424px 562px #fff, 228px 1740px #fff, 493px 1039px #fff, 375px 1820px #fff,
      788px 1184px #fff, 427px 230px #fff, 843px 924px #fff, 762px 1358px #fff,
      1188px 856px #fff, 1854px 1813px #fff, 643px 1514px #fff, 846px 965px #fff,
      1432px 1086px #fff, 550px 827px #fff, 667px 1782px #fff,
      1910px 1895px #fff, 692px 1935px #fff, 1713px 921px #fff,
      577px 1387px #fff, 1500px 1371px #fff, 1384px 492px #fff,
      1052px 1693px #fff, 916px 794px #fff, 1979px 1343px #fff, 943px 839px #fff,
      1851px 136px #fff, 21px 1554px #fff, 1789px 266px #fff, 1221px 17px #fff,
      369px 151px #fff, 1797px 1680px #fff, 1225px 1354px #fff, 915px 498px #fff,
      3px 1334px #fff, 752px 1743px #fff, 978px 1718px #fff, 753px 136px #fff,
      1015px 1098px #fff, 1561px 1154px #fff, 1762px 195px #fff,
      1715px 947px #fff, 1486px 501px #fff, 1089px 1609px #fff,
      1077px 577px #fff, 102px 780px #fff, 36px 826px #fff, 1288px 614px #fff,
      1601px 1687px #fff, 164px 655px #fff, 9px 89px #fff, 1010px 83px #fff,
      831px 58px #fff, 1221px 1404px #fff, 225px 290px #fff, 78px 919px #fff,
      321px 1684px #fff, 1372px 1405px #fff, 1854px 331px #fff,
      251px 1028px #fff, 1193px 652px #fff, 169px 1104px #fff,
      1790px 1505px #fff, 1126px 72px #fff, 1870px 1659px #fff, 1476px 58px #fff,
      1335px 1789px #fff, 443px 1809px #fff, 1457px 821px #fff,
      1594px 1096px #fff, 1371px 1538px #fff, 959px 359px #fff, 370px 649px #fff,
      1338px 1084px #fff, 1839px 710px #fff, 778px 1364px #fff,
      1115px 427px #fff, 1756px 1411px #fff, 627px 1920px #fff,
      999px 1354px #fff, 1974px 1946px #fff, 845px 1498px #fff,
      1642px 1888px #fff, 1799px 35px #fff, 1074px 1744px #fff,
      265px 1060px #fff, 772px 1538px #fff, 537px 881px #fff, 109px 141px #fff,
      903px 1364px #fff, 1549px 815px #fff, 227px 860px #fff, 1960px 149px #fff,
      1936px 1532px #fff, 212px 1291px #fff, 579px 608px #fff,
      1707px 1152px #fff, 750px 1066px #fff, 702px 668px #fff;
  }

  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1763px 172px #fff, 1091px 1534px #fff, 287px 1872px #fff,
      362px 1321px #fff, 1721px 916px #fff, 1092px 1869px #fff,
      1130px 1532px #fff, 934px 281px #fff, 1548px 1562px #fff,
      559px 1800px #fff, 827px 1286px #fff, 1401px 1167px #fff,
      526px 1650px #fff, 1822px 1177px #fff, 1717px 122px #fff, 1007px 14px #fff,
      14px 56px #fff, 902px 1135px #fff, 227px 1682px #fff, 723px 1647px #fff,
      505px 217px #fff, 1381px 1084px #fff, 1731px 175px #fff, 494px 342px #fff,
      838px 1536px #fff, 269px 388px #fff, 1820px 657px #fff, 21px 1537px #fff,
      1499px 1513px #fff, 849px 795px #fff, 193px 1956px #fff, 333px 99px #fff,
      1225px 166px #fff, 959px 628px #fff, 1383px 566px #fff, 942px 1245px #fff,
      1147px 1344px #fff, 1933px 1013px #fff, 749px 260px #fff, 517px 772px #fff,
      1623px 1659px #fff, 1389px 1354px #fff, 1436px 949px #fff,
      77px 1749px #fff, 471px 1658px #fff, 1066px 910px #fff, 208px 1296px #fff,
      337px 1654px #fff, 183px 1860px #fff, 902px 319px #fff, 1719px 760px #fff,
      1526px 600px #fff, 1618px 846px #fff, 905px 341px #fff, 712px 558px #fff,
      1274px 1491px #fff, 807px 1803px #fff, 1624px 952px #fff,
      1532px 1542px #fff, 1212px 703px #fff, 1644px 940px #fff,
      1227px 833px #fff, 1752px 857px #fff, 1472px 907px #fff,
      1768px 1710px #fff, 724px 229px #fff, 1770px 1392px #fff, 762px 879px #fff,
      415px 1474px #fff, 654px 1177px #fff, 976px 626px #fff, 553px 1672px #fff,
      1315px 12px #fff, 346px 478px #fff, 55px 1710px #fff, 976px 1182px #fff,
      351px 851px #fff, 128px 470px #fff, 1716px 101px #fff, 441px 1140px #fff,
      860px 1036px #fff, 528px 1324px #fff, 828px 229px #fff, 135px 170px #fff,
      423px 1873px #fff, 333px 620px #fff, 854px 1888px #fff, 764px 1285px #fff,
      2000px 1824px #fff, 1032px 1274px #fff, 1580px 1072px #fff,
      707px 43px #fff, 1391px 1953px #fff, 231px 632px #fff, 449px 1595px #fff,
      1987px 1047px #fff, 185px 715px #fff, 1940px 1413px #fff,
      1870px 902px #fff, 1445px 1324px #fff;
    animation: animStar 150s linear infinite;
  }
  #stars3:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1763px 172px #fff, 1091px 1534px #fff, 287px 1872px #fff,
      362px 1321px #fff, 1721px 916px #fff, 1092px 1869px #fff,
      1130px 1532px #fff, 934px 281px #fff, 1548px 1562px #fff,
      559px 1800px #fff, 827px 1286px #fff, 1401px 1167px #fff,
      526px 1650px #fff, 1822px 1177px #fff, 1717px 122px #fff, 1007px 14px #fff,
      14px 56px #fff, 902px 1135px #fff, 227px 1682px #fff, 723px 1647px #fff,
      505px 217px #fff, 1381px 1084px #fff, 1731px 175px #fff, 494px 342px #fff,
      838px 1536px #fff, 269px 388px #fff, 1820px 657px #fff, 21px 1537px #fff,
      1499px 1513px #fff, 849px 795px #fff, 193px 1956px #fff, 333px 99px #fff,
      1225px 166px #fff, 959px 628px #fff, 1383px 566px #fff, 942px 1245px #fff,
      1147px 1344px #fff, 1933px 1013px #fff, 749px 260px #fff, 517px 772px #fff,
      1623px 1659px #fff, 1389px 1354px #fff, 1436px 949px #fff,
      77px 1749px #fff, 471px 1658px #fff, 1066px 910px #fff, 208px 1296px #fff,
      337px 1654px #fff, 183px 1860px #fff, 902px 319px #fff, 1719px 760px #fff,
      1526px 600px #fff, 1618px 846px #fff, 905px 341px #fff, 712px 558px #fff,
      1274px 1491px #fff, 807px 1803px #fff, 1624px 952px #fff,
      1532px 1542px #fff, 1212px 703px #fff, 1644px 940px #fff,
      1227px 833px #fff, 1752px 857px #fff, 1472px 907px #fff,
      1768px 1710px #fff, 724px 229px #fff, 1770px 1392px #fff, 762px 879px #fff,
      415px 1474px #fff, 654px 1177px #fff, 976px 626px #fff, 553px 1672px #fff,
      1315px 12px #fff, 346px 478px #fff, 55px 1710px #fff, 976px 1182px #fff,
      351px 851px #fff, 128px 470px #fff, 1716px 101px #fff, 441px 1140px #fff,
      860px 1036px #fff, 528px 1324px #fff, 828px 229px #fff, 135px 170px #fff,
      423px 1873px #fff, 333px 620px #fff, 854px 1888px #fff, 764px 1285px #fff,
      2000px 1824px #fff, 1032px 1274px #fff, 1580px 1072px #fff,
      707px 43px #fff, 1391px 1953px #fff, 231px 632px #fff, 449px 1595px #fff,
      1987px 1047px #fff, 185px 715px #fff, 1940px 1413px #fff,
      1870px 902px #fff, 1445px 1324px #fff;
  }

  @keyframes animStar {
    from {
      transform: translateY(-500px);
    }
    to {
      transform: translateY(-2000px);
    }
  }
`

export default Stars
